<template>
  <div>
    <b-card title="Total Emails">
      <b-card-text>Hello admin, we have 100 new emails.</b-card-text>
      <b-card-text>You can check them out now from <b-link
        href="/emails"
        target="_self"
      >
        here
      </b-link>.
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
}
</script>

<style>

</style>
